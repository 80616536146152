// New Color Schema
$colorLead: #16191F;
$colorGrey: #313540;
$colorMercury: #545B64;
$colorPlatinum: #879196;
$colorPlatinumLight: #b1b8c1;
$colorSilver: #c1d0d1;
$colorZink: #D5DBDB;
$colorChromium: #EAEDED;
$colorMagnesiumDarker: #F5F5F5;
$colorMagnesium: #fafafa;
$colorCarbon: #fff;
$colorSmokeDark: #393F4F;
$colorSmoke: #424A5F;
$colorBackground: #F4F5F6;
$colorLightYellow: #fffde7;

$colorRedDark: #d32f2f;
$colorRed: #f44336;
$colorLightPink: #ffe6e3;
$colorAmber: #ffc107;
$colorYellow: #ffff00;
$colorYellowDark: #ffeb3b;
$colorLightBlueDark: #0288d1;
$colorLightBlue: #29B6F6;
$colorBlue: #2196f3;
$colorLightGreen: #64dd17;
$colorGreen: #4caf50;
$colorPurple: #9c27b0;

$colorMain: #f16e5e;
$colorMainHover: #d16052;
$colorMainActive: #b25246;

$colorDragItem: #e1f5fe;
$colorActiveAB: #e1f5fe;
$colorUpdateAB: #fffde7;
//

$colorLinks: $colorLightBlue;


$colorTargetButton: $colorLightBlue;

$colorControlsButton: $colorMain;
$colorControlsButton--active: $colorMainActive;

$colorLightButton: $colorCarbon;
$colorLightButton--hover: $colorMagnesium;
$colorLightButton--active: $colorBackground;

$colorText: $colorLead;


// table
$colorTableHead: $colorPlatinum;
$colorTableRow--new: $colorLead;
$colorTableRow--regular: $colorLead;
$colorTableRow--closed: $colorSilver;
// message
$colorMessageUserName: $colorLead;
$colorMessageHelpInfo: $colorSilver;
$colorMessageText: $colorLead;
// header
$colorBgHeader: $colorCarbon;

//subscription
$colorMainSubscription: #2a395a;
$colorGreenSubscription: #43707f;
$colorButtonSubscription: #239095;
